import React from "react"
import SEO from "../components/seo"
import Layout from "../components/layout"
import RevueSubscribe from "../components/revue-subscribe";


export default function Subscribe() {
  return (
    
    <div>
      <Layout>
      <SEO title="Subscribe"/>
      <RevueSubscribe /> 
      </Layout>
    </div>
  );
}
